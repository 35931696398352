
.apartment-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    background: linear-gradient(150deg, #f5f5f4 55%, white 50%);
}

.apartment-facilities-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 90%;
    gap: 20px;
}

@media only screen and (max-width: 450px) {
    .apartment-facilities-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}

@media only screen and (max-width: 320px) {
    .apartment-facilities-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
}
